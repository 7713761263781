:root{
    --primary: #47cffc;
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
        Helvetica Neue, sans-serif;
}

a {
    color: inherit;
}

* {
    box-sizing: border-box;
}

.c-mi {
    cursor: pointer;
    text-align: center;
    padding: 10px;
    line-height: 20px;
    text-decoration: none;

    &.on-drawer {
        padding: 0;
    }

    &:focus, &:focus-visible {
        outline: 0;
    }

    &.active, &:hover {
        color: var(--primary);
    }
}

.inline-lazy-image-bck {
    display: inline !important;
}

.hidden { display:none !important; }
